import { createAction, props } from '@ngrx/store';
import { gateWayModel } from './gateway.model';

//fetch
export const getGatewayList = createAction(
  '[gateWay],getGateway',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    id: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getGatewayListSucess = createAction(
  '[gateWay],fetch getGateway success',
  props<{ getGateway_Response: any }>()
);
export const getGatewayListFailure = createAction(
  '[gateWay],etch getGateway failed',
  props<{ getGatewayError: any }>()
);

//get All Gateway Export
export const getGatewayExport = createAction(
  '[gateWay],getGateway Export',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    id: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getGatewayExportSucess = createAction(
  '[gateWay],fetch getGateway  Export success',
  props<{ getGatewayExportResponse: any }>()
);
export const getGatewayExportFailure = createAction(
  '[gateWay],etch getGateway Export failed',
  props<{ getGatewayExportError: any }>()
);

export const resetgetGatewayState = createAction(
  '[App] Reset  fetch getGateway  Export'
);

export const getAllGatewayList = createAction(
  '[gateWay],getAllGateway',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    id: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllGatewayListSucess = createAction(
  '[gateWay],fetch getAllGateway success',
  props<{ getAllGateway_Response: any }>()
);
export const getAllGatewayListFailure = createAction(
  '[gateWay],etch getAllGateway failed',
  props<{ getAllGatewayError: any }>()
);

//Create gateway :
export const createGateway = createAction(
  '[gateWay] CreategetGatewayr',
  props<{ gateway: gateWayModel }>()
);
export const creategatewaySuccess = createAction(
  '[gateWay] getGateway Success',
  props<{ creategateway_Response: any }>()
);
export const creategatewayFailure = createAction(
  '[gateWay] getGateway Failure',
  props<{ createGatewayError: any }>()
);

// Update Data
export const updateGateWay = createAction(
  '[gateWay] Update gateWay',
  props<{ updatedData: gateWayModel }>()
);
export const updateGateWaySuccess = createAction(
  '[gateWay] Update gateWay Success',
  props<{ updateGateWayResponse: any }>()
);
export const updateGateWayFailure = createAction(
  '[gateWay] Update gateWay Failure',
  props<{ updateGateWayError: any }>()
);

// Delete Data
export const deleteGateWay = createAction(
  '[gateWay] Delete gateWay',
  props<{ id: string }>()
);
export const deleteGateWaySuccess = createAction(
  '[gateWay] Delete gateWay Success',
  props<{ deleteGateWayResponse: any }>()
);
export const deleteGateWayFailure = createAction(
  '[gateWay] Delete gateWay Failure',
  props<{ deleteGateWayError: any }>()
);

export const SetGatewayStatus = createAction(
  '[gateWay] SetGatewayStatus',
  props<{ request: any }>()
);

export const resetGateWaysState = createAction('[resetGateWaysState ] resetGateWaysState State');