<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <span class="breadcrumb m-0">
          @if(cdkStepper.selectedIndex === 1){ <button class="btn btn-primary d-flex align-items-center"
            (click)="exportAll()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>}
          @if(cdkStepper.selectedIndex === 2){ <button class="btn btn-primary d-flex align-items-center"
            (click)="exportGatewayAll()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>}

        </span>


      </div>
    </div>
  </div>



  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title mb-4">Project Details</h4> -->
          <div id="basic-example">
            <ng-stepper #cdkStepper [linear]="true" class="wizard custom-steeper-paddding sm-stepper"
              (selectionChange)="onStepChange($event)">

              <cdk-step [optional]="false">
                <ng-template cdkStepLabel>
                  <span class="number">1</span>
                  <span>Project Details</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 0){
                <section class="p-2">
                  <form [formGroup]="projectForm">
                    <div class="row mb-2 d-sm-block d-md-none">
                      <h4 class="card-title mb-4 text-decoration-underline">Project Details </h4>
                    </div>
                    <div class="row ">
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="">Project name</label>
                          <p>{{projectDetails?.name}}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Project type</label>
                          <p>{{projectDetails?.projectType?.name}}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Region</label>
                          <p>{{projectDetails?.region?.name}}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Project Address</label>
                          <p>{{projectDetails?.address}}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Area/Locality</label>
                          <p>{{projectDetails?.area}}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">City</label>
                          <p>{{projectDetails?.city?.name}}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">State</label>
                          <p>{{projectDetails?.state?.name}}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Pincode</label>
                          <p>{{projectDetails?.pinCode}}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Billing Type </label>
                          <p>{{projectDetails?.billingType?.name}}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Project Bill Date</label>
                          <p>
                            {{
                            projectDetails?.billingType?.name === 'Prepaid'
                            ? 'Daily'
                            : formatBillingDate(projectDetails?.billDate)
                            }}

                          </p>

                        </div>
                      </div>



                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Selected Utilities</label>
                          <p>{{selectedUtilityNames}}</p>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Project Contact Number</label>
                          <p>{{ projectDetails?.contactNumber}}</p>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Project Contact Email</label>
                          <p>{{ projectDetails?.email ? projectDetails.email : 'N/A' }}</p>
                        </div>
                      </div>
                      <!--  <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Project Start Date</label>
                          <p>{{ projectDetails.startDate!= null?
                            (projectDetails.startDate |date: 'dd MMM yyyy hh:mm a' )
                            :'-' }}
                          </p>

                        </div>
                      </div> -->
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Project Start Date</label>
                          <p>{{ projectDetails?.startDate!= null?
                            (projectDetails?.startDate |date: 'dd MMM yyyy' )
                            :'-' }}
                          </p>

                        </div>

                      </div>


                      <div class="col-lg-3" *ngIf="projectDetails?.billingType?.name == 'Postpaid'">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Project Due Date</label>
                          <p>
                            {{
                            formatDueDate(projectDetails.dueDate)
                            }}

                          </p>
                        </div>
                      </div>

                      <div class="col-lg-3" *ngIf="projectDetails?.billingType?.name == 'Postpaid'">
                        <div class="mb-4">
                          <label for="basicpill-declaration-input">Billing Initiated <span
                              class="required">*</span></label>
                          <ng-select [items]="billingInitiatedStatus" bindLabel="label" bindValue="value"
                            formControlName="IsBillingInitiated" id="IsBillingInitiated" [multiple]="false"
                            [disabled]="true"></ng-select>
                          @if((submitted || projectForm.controls['IsBillingInitiated'].touched)&&
                          projectForm.controls['IsBillingInitiated'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['IsBillingInitiated'].errors['required']) {
                            <!-- <div>{{projectValidations.IsBillingInitiated.required}}</div> -->
                            }
                          </div>}
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-lg-6">
                          <label for="LogoFile">Upload Logo</label>
                          <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl" (upload)="onUpload()"
                            (success)="onSuccess()" formControlName="LogoFile" id="LogoFile" [batch]="false"
                            [showFileList]="true" (remove)="onRemove($event)" [multiple]="false"
                            accept=".png,.jpg,.jpeg">
                          </kendo-upload>
                          <span class="mt-3" *ngIf="projectDetails?.logoUrl">
                            <div class="logo-container">
                              <div class="logo-wrapper">
                                <img [src]="projectDetails?.logoUrl" class="project_logo" alt="Logo" />
                                <button type="button" class="btn download-btn" title="Download Logo"
                                  (click)="downloadFile(projectDetails.logoUrl, projectDetails.logoFileName)">
                                  <i class="fa fa-download"></i>
                                </button>
                              </div>
                            </div>
                          </span>
                        </div>
                        <!-- </div>
                      
                      <div class="row mt-3"> -->
                        <div class="col-lg-6">
                          <label for="basicpill-declaration-input">Upload Cancelled Cheque</label>
                          <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl"
                            formControlName="cancelled_Cheque_file" id="cancelled_Cheque_file" [batch]="false">
                          </kendo-upload>

                          <span class="mt-3" *ngIf="projectDetails?.societyHead?.fileUrl">
                            <div class="logo-container">
                              <div class="logo-wrapper">
                                <!-- Conditionally display the image or file icon -->
                                <ng-container *ngIf="isImageFile(projectDetails?.societyHead?.fileName); else fileIcon">
                                  <img [src]="projectDetails?.societyHead?.fileUrl" class="project_logo"
                                    alt="Cancelled Cheque" />
                                </ng-container>
                                <!-- File icon template -->
                                <ng-template #fileIcon>
                                  <i class="fa fa-file" aria-hidden="true" style="font-size: 40px;"></i>
                                </ng-template>

                                <!-- Download button -->
                                <button type="button" class="btn download-btn" title="Download File"
                                  (click)="downloadFile(projectDetails?.societyHead?.fileUrl, projectDetails?.societyHead?.fileName)">
                                  <i class="fa fa-download"></i>
                                </button>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>





                    </div>
                  </form>

                </section>

                <section class="mt-4 p-2">
                  <h4 class="card-title mb-4 text-decoration-underline">Society Head Details</h4>
                  <form>
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="">Name</label>
                          <p>{{ projectDetails?.societyHead?.name || 'N/A' }}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Merchant ID</label>
                          <p>{{projectDetails?.societyHead?.merchantId || 'N/A' }}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Account Name</label>
                          <p>{{projectDetails?.societyHead?.accountName || 'N/A' }}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Account Number</label>
                          <p>{{projectDetails?.societyHead?.accountNumber || 'N/A' }}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Bank Name</label>
                          <p>{{projectDetails?.societyHead?.bankName || 'N/A' }}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Branch Name</label>
                          <p>{{projectDetails?.societyHead?.branchName || 'N/A' }}</p>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">IFSC Code</label>
                          <p>{{projectDetails?.societyHead?.ifscCode || 'N/A' }}</p>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">GST Number</label>
                          <p>{{projectDetails?.gsttino || 'N/A' }}</p>
                        </div>
                      </div>
                    </div>

                  </form>


                </section>

                <form [formGroup]="projectForm">



                  <section class="mt-4 p-2">
                    <h4 class="card-title mb-4 text-decoration-underline">Billing Details</h4>


                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-4">
                          <label for="basicpill-pancard-input">Bill Type</label>
                          <ng-select [items]="configData?.data?.billingTypes" formControlName="billingType"
                            bindLabel="name" bindValue="id" [multiple]="false" [placeholder]="'Select...'"></ng-select>
                        </div>
                      </div>

                      <div class="col-lg-4"
                        *ngIf="projectDetails?.isWalletEnabled==true || projectDetails?.billingType?.name === 'Prepaid'">
                        <div class="mb-4">
                          <label for="basicpill-vatno-input">Minimum Wallet Balance (₹) <span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="minWalletBalance" value=""
                            placeholder="Enter Minimum Recharge Amount " id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['minWalletBalance'].touched)&&
                          projectForm.controls['minWalletBalance'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['minWalletBalance'].errors['required']) {
                            <div>{{projectValidations.minWalletBalance.required}}</div>}
                            @if (projectForm.controls['minWalletBalance'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.minWalletBalance.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4" *ngIf="isUtilityDisplay(UtilityType.GAS)">
                        <div class="mb-4">
                          <label for="basicpill-servicetax-input">Valve Cut Off Limit (₹)<span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="valveCutofflimit" value=""
                            placeholder="Enter Valve Cut Limit" id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['valveCutofflimit'].touched) &&
                          projectForm.controls['valveCutofflimit'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['valveCutofflimit'].errors['required']) {
                            <div>{{projectValidations.valveCutofflimit.required}}</div>}
                            @if (projectForm.controls['valveCutofflimit'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.valveCutofflimit.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4" *ngIf="isUtilityDisplay(UtilityType.ELECTRICITY)">
                        <div class="mb-4">
                          <label for="basicpill-servicetax-input">Relay Cut-off Limit (₹)<span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="relayCutofflimit" value=""
                            placeholder="Enter Relay Cut off Limit" id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['relayCutofflimit'].touched) &&
                          projectForm.controls['relayCutofflimit'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['relayCutofflimit'].errors['required']) {
                            <div>{{projectValidations.relayCutofflimit.required}}</div>}
                            @if (projectForm.controls['relayCutofflimit'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.relayCutofflimit.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4"
                        *ngIf="projectDetails?.isWalletEnabled == true|| projectDetails?.billingType?.name === 'Prepaid'">
                        <div class="mb-4">
                          <label for="basicpill-vatno-input">Minimum Recharge Amount (₹) <span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="minRechargeAmount" value=""
                            placeholder="Enter Minimum Recharge Amount " id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['minRechargeAmount'].touched)&&
                          projectForm.controls['minRechargeAmount'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['minRechargeAmount'].errors['required']) {
                            <div>{{projectValidations.minRechargeAmount.required}}</div>}
                            @if (projectForm.controls['minRechargeAmount'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.minRechargeAmount.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>


                      <div class="col-lg-4"
                        *ngIf="projectDetails?.isWalletEnabled == true|| projectDetails?.billingType?.name === 'Prepaid'">
                        <div class="mb-4">
                          <label for="basicpill-companyuin-input">Maximum Recharge Amount (₹) <span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="maxRechargeAmount" value=""
                            placeholder="Enter Maximum Recharge Amount" id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['maxRechargeAmount'].touched)&&
                          projectForm.controls['maxRechargeAmount'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['maxRechargeAmount'].errors['required']) {
                            <div>{{projectValidations.maxRechargeAmount.required}}</div>}
                            @if (projectForm.controls['maxRechargeAmount'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.maxRechargeAmount.patternValidation}}</div>
                            }

                          </div>}
                        </div>
                      </div>



                      <div class="col-lg-4" *ngIf="isDisplay(UtilityType.WATER)">
                        <div class="mb-3">
                          <label for="basicpill-companyuin-input">water Consumption Limit (L)<span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="waterConsumptionLimit"
                            id="waterConsumptionLimit" placeholder="Enter water Consumption Limit"
                            id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['waterConsumptionLimit'].touched)&&
                          projectForm.controls['waterConsumptionLimit'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['waterConsumptionLimit'].errors['required']) {
                            <div>{{projectValidations.waterConsumptionLimit.required}}</div>}
                            @if (projectForm.controls['waterConsumptionLimit'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.waterConsumptionLimit.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4" *ngIf="isDisplay(UtilityType.GAS)">
                        <div class="mb-3">
                          <label for="basicpill-companyuin-input">Gas Consumption Limit (m³)<span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="gasConsumptionLimit"
                            id="gasConsumptionLimit" placeholder="Enter Gas Consumption Limit"
                            id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['gasConsumptionLimit'].touched)&&
                          projectForm.controls['gasConsumptionLimit'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['gasConsumptionLimit'].errors['required']) {
                            <div>{{projectValidations.gasConsumptionLimit.required}}</div>}
                            @if (projectForm.controls['gasConsumptionLimit'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.gasConsumptionLimit.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>


                      <div class="col-lg-4" *ngIf="isDisplay(UtilityType.ELECTRICITY)">
                        <div class="mb-3">
                          <label for="basicpill-companyuin-input">Electricity DG Consumption Limit (kWh)<span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="electricityDGConsumptionLimit"
                            id="electricityDGConsumptionLimit" placeholder="Enter Electricity Dg Consumption Limit"
                            id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['electricityDGConsumptionLimit'].touched)&&
                          projectForm.controls['electricityDGConsumptionLimit'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['electricityDGConsumptionLimit'].errors['required']) {
                            <div>{{projectValidations.electricityDGConsumptionLimit.required}}</div>}
                            @if (projectForm.controls['electricityDGConsumptionLimit'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.electricityDGConsumptionLimit.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4" *ngIf="isDisplay(UtilityType.ELECTRICITY)">
                        <div class="mb-3">
                          <label for="basicpill-companyuin-input">Electricity EB Consumption Limit (kWh) <span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="electricityEBConsumptionLimit"
                            id="electricityEBConsumptionLimit" placeholder="Enter Electricity Eb Consumption Limit"
                            id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['electricityEBConsumptionLimit'].touched)&&
                          projectForm.controls['electricityEBConsumptionLimit'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['electricityEBConsumptionLimit'].errors['required']) {
                            <div>{{projectValidations.electricityEBConsumptionLimit.required}}</div>}
                            @if (projectForm.controls['electricityEBConsumptionLimit'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.electricityEBConsumptionLimit.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>



                      <div class="col-lg-4" *ngIf="projectDetails?.billingType?.name == 'Postpaid'">
                        <div class="mb-3">
                          <label for="basicpill-declaration-input">Valve CutOff Date<span
                              class="required">*</span></label>
                          <!-- <input class="form-control" type="date" formControlName="billDate" id="billDate"
                                                  value="2019-08-19" id="example-date-input"> -->

                          <ng-select [items]="projectBillingDates" formControlName="ValveCutOffDate"
                            id="ValveCutOffDate" [multiple]="false"></ng-select>
                          @if((submitted || projectForm.controls['ValveCutOffDate'].touched)&&
                          projectForm.controls['ValveCutOffDate'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['ValveCutOffDate'].errors['required']) {
                            <div>{{projectValidations.ValveCutOffDate.required}}</div>}
                          </div>}
                        </div>
                      </div>


                      <div class="col-lg-4" *ngIf="projectDetails?.billingType?.name == 'Postpaid'">
                        <div class="mb-3">
                          <label for="basicpill-declaration-input">Relay CutOff Date<span
                              class="required">*</span></label>
                          <!-- <input class="form-control" type="date" formControlName="billDate" id="billDate"
                                                  value="2019-08-19" id="example-date-input"> -->

                          <ng-select [items]="projectBillingDates" formControlName="RelayCutOffDate"
                            id="RelayCutOffDate" [multiple]="false"></ng-select>
                          @if((submitted || projectForm.controls['RelayCutOffDate'].touched)&&
                          projectForm.controls['RelayCutOffDate'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['RelayCutOffDate'].errors['required']) {
                            <div>{{projectValidations.RelayCutOffDate.required}}</div>}
                          </div>}
                        </div>
                      </div>


                      <!-- <div class="col-lg-4">
                        <div class="mb-4">
                          <label for="basicpill-declaration-input">Project Bill Date</label>
                          <ng-select [items]="projectBillingDates" formControlName="billDate" id="billDate"
                            [multiple]="false"></ng-select>
                        </div>
                      </div> -->

                    </div>



                  </section>

                  <section class="mt-4 p-2">
                    <h4 class="card-title mb-4 text-decoration-underline">Provider Details</h4>
                    <!-- <form> -->
                    <div class="row">
                      <div class="col-lg-4" *ngIf="currentUser?.projectUtilities&&isUtilityDisplay('Gas')">
                        <div class="mb-4">
                          <label for="basicpill-pancard-input">Gas<span class="required">*</span></label>
                          <ng-select [items]="configData?.data?.gasProviders" formControlName="gasProvider"
                            bindLabel="name" bindValue="id" [multiple]="true" [placeholder]="'Select...'"></ng-select>
                          @if((submitted || projectForm.controls['gasProvider'].touched) &&
                          projectForm.controls['gasProvider'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['gasProvider'].errors['required']) {
                            <div>{{projectValidations.gasProvider.required}}</div>}
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4" *ngIf="currentUser?.projectUtilities&&isUtilityDisplay('Electricity')">
                        <div class="mb-4">
                          <label for="basicpill-vatno-input">Electricity<span class="required">*</span></label>
                          <ng-select [items]="configData?.data?.electricityProviders"
                            formControlName="electricityProvider" bindLabel="name" bindValue="id" [multiple]="true"
                            [placeholder]="'Select...'"></ng-select>
                          @if((submitted || projectForm.controls['electricityProvider'].touched) &&
                          projectForm.controls['electricityProvider'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['electricityProvider'].errors['required']) {
                            <div>{{projectValidations.electricityProvider.required}}</div>}
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4" *ngIf="currentUser?.projectUtilities&&isUtilityDisplay('Water')">
                        <div class="mb-4">
                          <label for="basicpill-servicetax-input">Water<span class="required">*</span></label>
                          <ng-select [items]="configData?.data?.waterProviders" formControlName="waterProvider"
                            bindLabel="name" bindValue="id" [multiple]="true" [placeholder]="'Select...'"></ng-select>
                          @if((submitted || projectForm.controls['waterProvider'].touched)&&
                          projectForm.controls['waterProvider'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['waterProvider'].errors['required']) {
                            <div>{{projectValidations.waterProvider.required}}</div>}
                          </div>}
                        </div>
                      </div>

                      <!-- <div class="col-lg-4">
                        <div class="mb-4">

                        </div>
                      </div> -->

                      <div class="row">
                        <div class="col-lg-4">
                          <div class="mb-4">
                            <div class="form-check">
                              <label class="form-check-label" for="formCheck1">
                                Email Notification
                              </label>
                              <input class="form-check-input" type="checkbox" formControlName="emailNotifications"
                                id="formCheck1">

                            </div>
                            <!-- <div class="form-check mb-3">
                            <input class="form-check-input" formControlName="smsNotifications" type="checkbox"
                              id="formCheck2">
                            <label class="form-check-label" for="formCheck2">
                              SMS Notifications
                            </label>
                          </div> -->
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="mb-4">
                            <!-- <div class="form-check  ">
                            <input class="form-check-input" type="checkbox" formControlName="emailNotifications"
                              id="formCheck1">
                            <label class="form-check-label" for="formCheck1">
                              Email Notification
                            </label>
                          </div> -->
                            <div class="form-check mb-3">
                              <input class="form-check-input" formControlName="smsNotifications" type="checkbox"
                                id="formCheck2">
                              <label class="form-check-label" for="formCheck2">
                                SMS Notifications
                              </label>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="mb-4">
                            <div class="form-check mb-3">
                              <input class="form-check-input" formControlName="meterOnOff" type="checkbox"
                                id="formCheck2">
                              <label class="form-check-label" for="formCheck2"
                                *ngIf=" projectDetails  &&  projectDetails?.billingType && projectDetails?.billingType?.name && projectDetails?.billingType?.name   == 'Prepaid'">
                                send meter off request for negative wallet
                              </label>
                              <label class="form-check-label" for="formCheck2"
                                *ngIf="projectDetails &&    projectDetails?.billingType &&   projectDetails?.billingType?.name  && projectDetails?.billingType?.name== 'Postpaid'">
                                Stop sending meter off request during billing
                              </label>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="mb-4">
                            <div class="form-check mb-3">
                              <input class="form-check-input" formControlName="IsDisplayOldBills" id="IsDisplayOldBills"
                                type="checkbox" id="formCheck2">
                              <label class="form-check-label" for="formCheck2">
                                Display Old Bills
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    @if(currentUserData?.currentUserRoleDetails.role==roles.ADMIN){ <ul
                      class="list-inline wizard d-flex justify-content-end mb-0">
                      <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-primary"
                          [disabled]="isUploading" (click)="updateProject()">Save</button></li>
                      <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary"
                          cdkStepperNext>Next</button></li>
                    </ul>}
                  </section>
                </form>

                }
              </cdk-step>
              @if(currentUserData?.currentUserRoleDetails.role==roles.ADMIN){ <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">2</span>
                  <span>Monitoring Devices</span>
                </ng-template>
                <!--  gate Way GRid   Start-->
                @if(cdkStepper.selectedIndex === 1){
                <section>
                  <div class="row mb-2 d-sm-block d-md-none">
                    <h4 class="card-title mb-4 text-decoration-underline">Monitoring Devices</h4>
                  </div>

                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">
                    <kendo-grid [data]="monitoringGridData" [pageSize]="monitoring_pageSize" [skip]="skip"
                      [sortable]="true" [pageable]="{
                                    buttonCount: buttonCount,
                                    info: info,
                                    type: type,
                                    pageSizes: pageSizes,
                                    previousNext: previousNext
                                  }" [reorderable]="true" filterable="menu" [filter]="monitoringDeviceState.filter"
                      [sort]="monitoringDeviceState.sort" [columnMenu]="{ filter: true }"
                      (filterChange)="onMonitoringFilterChange($event)" (sortChange)="onMonitoringSortChange($event)"
                      [resizable]="true" scrollable="scrollable" [selectable]="selectableSettings"
                      (pageChange)="monitoringPageChange($event)" (dataStateChange)="supplierDataStateChange($event)"
                      (selectionChange)="monitoringOnSelect($event)">

                      <ng-template kendoGridToolbarTemplate>
                        <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                          [(ngModel)]="monitoringSearch" (valueChange)="onMonitoringFilter($event)"></kendo-textbox> -->
                        <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [style.height.px]="35" [clearButton]="true"
                            (valueChange)="monitoringValueChange($event)" (keydown.enter)="onSearchMonitoringDevices()"
                            [(ngModel)]="monitoringSearch" placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary btn-md border-left-none" (click)="onMonitoringFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <kendo-grid-spacer></kendo-grid-spacer>
                        <button class="btn btn-outline-primary btn-sm" (click)="addMoniterModal()">Add New </button>
                        <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                          (click)="monitoringonExport('Excel')">
                          <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                        </button>
                        <!-- <button class="btn btn-light" (click)="monitoringonExport('Pdf')">
                                        <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->

                      </ng-template>


                      <div>
                        <kendo-grid
                          [kendoGridBinding]="MonitoringselectedRowdata.length>0 ? MonitoringselectedRowdata:allMonitoringList.length>0?allMonitoringList:monitoringGridData.data"
                          #pdfGrid_2>
                          <kendo-grid-column field="utility.name" title="Utility"></kendo-grid-column>
                          <kendo-grid-column field="meterNumber" title="Meter Number"></kendo-grid-column>
                          <kendo-grid-pdf fileName="monitoring.pdf" [allPages]="true" paperSize="A4"
                            [landscape]="true"></kendo-grid-pdf>
                          <kendo-grid-excel fileName="monitoring.xlsx"></kendo-grid-excel>
                        </kendo-grid>
                      </div>

                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>


                      <kendo-grid-column field="utility.name" title="Utility" [width]="200"></kendo-grid-column>
                      <kendo-grid-column field="meterNumber" title="Meter Number" [width]="200"></kendo-grid-column>
                      <kendo-grid-command-column title="" [columnMenu]="false" [width]="70">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="d-flex">
                            <!-- Edit Project Icon -->
                            <span kendoTooltip title="Edit Monitorings" class="me-3"
                              (click)="updateMonitoring(dataItem)">
                              <i class="fas fa-edit text-primary" style="cursor: pointer;"></i>
                            </span>

                            <!-- Mapped Utilities Icon -->
                            <span kendoTooltip title="Linked Meters" class="me-3" (click)="gotoMappedList(dataItem)">
                              <i class="fas fa-link text-secondary" style="cursor: pointer;"></i>
                            </span>

                            <!-- Delete Project Icon -->
                            <span kendoTooltip title="Delete Monitoring" class="me-3" (click)="deleteMonitor(dataItem)">
                              <i class="fas fa-trash-alt text-danger" style="cursor: pointer;"></i>
                            </span>
                          </div>
                        </ng-template>
                      </kendo-grid-command-column>

                    </kendo-grid>

                  </div>
                  <ul class="list-inline wizard d-flex justify-content-end mt-4">
                    <li *ngIf="projectDetails?.isGIReadable" class="next list-inline-item" aria-disabled="false"><button
                        class="btn btn-primary" cdkStepperNext>Next</button></li>
                    <li *ngIf !="projectDetails?.isGIReadable" class="previous list-inline-item" aria-disabled="true">
                      <button class="btn btn-outline-primary" cdkStepperPrevious>Previous</button>
                    </li>
                    <!-- <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary" cdkStepperNext>Next</button></li> -->
                  </ul>
                </section>
                }
              </cdk-step>
              <!--  gate Way GRid  End -->
              <cdk-step [optional]="true" *ngIf="projectDetails?.isGIReadable">
                <ng-template cdkStepLabel>
                  <span class="number">3</span>
                  <span>Gateway Information </span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 2){
                <section>
                  <div class="row mb-2 d-sm-block d-md-none">
                    <h4 class="card-title mb-4 text-decoration-underline">Gateway Information</h4>
                  </div>
                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">
                    <!--  @if(cdkStepper.selectedIndex === 1){ <div></div>} -->
                    <kendo-grid [data]="gatewaygridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                      [pageable]="{
                                      buttonCount: buttonCount,
                                      info: info,
                                      type: type,
                                      pageSizes: pageSizes,
                                      previousNext: previousNext
                                    }" [reorderable]="true" filterable="menu" [filter]="gateWayState.filter"
                      (filterChange)="onGateWayFilterChange($event)" (sortChange)="onGatewaySortChange($event)"
                      [sort]="gateWayState.sort" [columnMenu]="{ filter: true }" [resizable]="true"
                      scrollable="scrollable" [selectable]="selectableSettings" (pageChange)="gatewayPageChange($event)"
                      (dataStateChange)="dataStateChange($event)" (selectionChange)="gatewayOnSelect($event)">


                      <ng-template kendoGridToolbarTemplate>
                        <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [style.height.px]="35" [style.height.px]="35"
                            [(ngModel)]="gateWaySearch" [clearButton]="true" (valueChange)="gatewatValueChange($event)"
                            (keydown.enter)="onSearchGateWayDevices()" placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary btn-md border-left-none" (click)="ongatewayFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>

                        <kendo-grid-spacer></kendo-grid-spacer>
                        <button class="btn btn-outline-primary btn-sm" hidden="true" (click)="addNewGateWay()">Add
                          New
                        </button>

                        <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                          (click)="gatewayonExport('Excel')">
                          <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                        </button>

                        <!-- <button kendoGridPDFCommand class="btn btn-light" (click)="gatewayonExport('Pdf')">
                                          <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->

                      </ng-template>


                      <!-- Pdf   start-->
                      <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                        <kendo-grid
                          [kendoGridBinding]="selectedRowdata.length>0 ? selectedRowdata:allGatewayList.length>0?allGatewayList:gatewaygridData.data"
                          #pdfGrid>
                          <kendo-grid-column field="serialNumber" title="Gateway Serial Number"></kendo-grid-column>
                          <kendo-grid-column field="isWiredGateWay" title=" Is Wired GateWay"></kendo-grid-column>
                          <kendo-grid-column field="gatewayProvider.name" title="Gateway"></kendo-grid-column>
                          <!-- <kendo-grid-pdf fileName="gateway.pdf" [allPages]="true" paperSize="A4"
                            [landscape]="true"></kendo-grid-pdf> -->
                          <kendo-grid-excel fileName="gateway.xlsx"></kendo-grid-excel>
                        </kendo-grid>
                      </div>

                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>

                      <kendo-grid-column field="serialNumber" title="Gateway Serial Number"></kendo-grid-column>
                      <kendo-grid-column field="isWiredGateWay" title=" Is Wired GateWay"></kendo-grid-column>

                      <kendo-grid-column field="gatewayProvider.name" title="Gateway"></kendo-grid-column>
                      <kendo-grid-command-column title="" [hidden]="true" [columnMenu]="false" [width]="90">

                      </kendo-grid-command-column>

                    </kendo-grid>
                  </div>
                  <ul class="list-inline wizard d-flex justify-content-end mt-4">
                    <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                        cdkStepperPrevious>Previous</button></li>
                    <!-- <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary"
                        cdkStepperNext>Next</button>
                    </li> -->
                  </ul>
                </section>
                }
              </cdk-step>
              }

            </ng-stepper>
          </div>

        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>

</div>