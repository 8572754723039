.resetBtn{
    margin-left: 5%;
}

.k-grid .k-grid-content td {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  .instanceclr{
    background-color: #f37e53;
    color: white;
  }